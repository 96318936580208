import i18n from 'locales/i18n';
import { PATH_ADMIN, PATH_FLORISTS, PATH_MAIN, PATH_MANAGE } from 'routes/paths';
// components

// ----------------------------------------------------------------------

import Iconify from 'components/Iconify';

const getIcon = (name) => <Iconify icon={name} />;

const ICONS = {
    orders: getIcon('bi:bag-dash'),
    search: getIcon('bi:search'),
    transactions: getIcon('icon-park-outline:exchange-three'),
    console: getIcon('carbon:data-table-reference'),
    audits: getIcon('carbon:cloud-auditing'),
    report: getIcon('carbon:report-data'),
    analytics: getIcon('carbon:analytics-reference'),
    florist: getIcon('ph:flower-lotus-light'),
    logs: getIcon('carbon:flow-logs-vpc'),
    group: getIcon('akar-icons:people-group'),
    department: getIcon('carbon:group-access'),
    florists: getIcon('icon-park-outline:geometric-flowers'),
    // contacts: getIcon('fluent:contact-card-28-regular'),
    teams: getIcon('fluent:people-team-toolbox-24-regular'),
    access: getIcon('bi:shield-lock'),
    settings: getIcon('simple-line-icons:settings'),
    role: getIcon('carbon:user-role'),
    products: getIcon('ic:round-production-quantity-limits'),
    discount: getIcon('emojione-monotone:money-bag'),
    offdays: getIcon('fontisto:date'),
    daily_reports: getIcon('ph:files-duotone'),
    occasions: getIcon('carbon:license-third-party-draft'),
};

const getTranslate = (key) => i18n.t(`sidebar.${key}`);

const navConfig = [
    {
        subheader: getTranslate('dashboard'),
        permission: 'SHOW_DASHBOARD',
        items: [
            {
                title: getTranslate('today_orders'),
                path: PATH_MAIN.today_orders,
                icon: ICONS.orders,
                permission: 'ORDER_SEARCH',
            },
            {
                title: getTranslate('daily_reports'),
                path: PATH_MAIN.daily_reports,
                icon: ICONS.daily_reports,
                permission: 'ORDER_SEARCH',
            },
            {
                title: getTranslate('archived_orders'),
                path: PATH_MAIN.archived_orders,
                icon: ICONS.search,
                permission: 'ARCHIVED_SEARCH',
            },
            {
                title: getTranslate('custom_order'),
                path: PATH_MAIN.custom_order,
                icon: ICONS.orders,
                permission: 'ORDER_SEARCH',
            },
            {
                title: getTranslate('transactions'),
                path: PATH_MAIN.transactions,
                icon: ICONS.transactions,
                permission: '',
            },
            // {
            //   title: getTranslate('order_consolidations'),
            //   path: PATH_MAIN.order_consolidations,
            //   icon: ICONS.console,
            //   permission: 'CONSOLIDATION_SEARCH',
            // },
            {
                title: getTranslate('order_audits'),
                path: PATH_MAIN.order_audits,
                icon: ICONS.audits,
                permission: 'AUDITS_SEARCH',
            },
            {
                title: getTranslate('vendor_reports'),
                path: PATH_MAIN.vendor_reports,
                icon: ICONS.report,
                permission: 'VENDOR_SEARCH',
            },
        ],
    },
    {
        subheader: getTranslate('admin'),
        permission: 'SHOW_ADMIN',
        items: [
            {
                title: getTranslate('reports'),
                path: PATH_ADMIN.bank_reports,
                icon: ICONS.analytics,
                permission: 'ADMIN_REPORTS',
                children: [
                    {
                        title: getTranslate('debt_profit_reports'),
                        path: PATH_ADMIN.debt_and_profit,
                        permission: 'DEBT_PROFİT_REPORTS',
                    },
                    {
                        title: getTranslate('phone_orders_reports'),
                        path: PATH_ADMIN.phone_orders_reports,
                        permission: 'DEBT_PROFİT_REPORTS',
                    },
                    { title: getTranslate('bank_reports'), path: PATH_ADMIN.bank_reports, permission: 'BANK_REPORTS' },
                    {
                        title: getTranslate('ccard_reports'),
                        path: PATH_ADMIN.ccard_reports,
                        permission: 'CREDIT_CARD_REPORTS',
                    },
                    {
                        title: getTranslate('refund_reports'),
                        path: PATH_ADMIN.refund_reports,
                        permission: 'REFUND_REPORTS',
                    },
                    {
                        title: getTranslate('sales_by_states'),
                        path: PATH_ADMIN.sales_by_states,
                        permission: 'SALES_BY_STATES_REPORTS',
                    },
                    {
                        title: getTranslate('sales_agent_reports'),
                        path: PATH_ADMIN.sales_agent_reports,
                        permission: 'SHOW_SALES_AGENT_REPORTS', // SHOW_SALES_AGENT_REPORTS
                    },
                    {
                        title: getTranslate('occasion_reports'),
                        path: PATH_ADMIN.occasion_reports,
                        permission: 'OCCASION_REPORTS',
                    },
                    {
                        title: getTranslate('recurring_reports'),
                        path: PATH_ADMIN.recurring_reports,
                        permission: 'RECURRING_REPORTS',
                    },
                    {
                        title: getTranslate('florists_reports'),
                        path: PATH_ADMIN.florists_reports,
                        permission: 'FLORISTS_REPORTS',
                    },
                ],
            },
        ],
    },
    {
        subheader: getTranslate('florist_manage'),
        permission: 'SHOW_FLORIST_MANAGE',
        items: [
            {
                title: getTranslate('florists'),
                path: PATH_FLORISTS.florists,
                icon: ICONS.florist,
                permission: 'FLORISTS_MENU',
                children: [
                    {
                        title: getTranslate('florists_manage'),
                        path: PATH_FLORISTS.florists_manage,
                        permission: 'MANAGE_FLORISTS', // MANAGE_FLORISTS
                    },
                    // {
                    //   title: getTranslate('florists_list'),
                    //   path: PATH_FLORISTS.florists_manage_new,
                    //   permission: 'MANAGE_FLORISTS', // MANAGE_FLORISTS
                    // },
                    {
                        title: getTranslate('florists_create'),
                        path: PATH_FLORISTS.florists_create,
                        permission: 'CREATE_FLORISTS', // CREATE_FLORISTS
                    },
                ],
            },
        ],
    },
    {
        subheader: getTranslate('transactions'),
        permission: 'SHOW_TRANSACTIONS',
        items: [
            {
                title: getTranslate('transactions_logs'),
                path: PATH_MANAGE.transactions_logs,
                icon: ICONS.logs,
                permission: 'SHOW_TRANSACTION_LOGS', // SHOW_TRANSACTION_LOGS
                children: [
                    {
                        title: getTranslate('crm_database_logs'),
                        path: PATH_MANAGE.crm_database_logs,
                        permission: 'SHOW_DATABASE_LOGS', // SHOW_DATABASE_LOGS
                    },
                    {
                        title: getTranslate('payment_logs'),
                        path: PATH_MANAGE.payment_logs,
                        permission: 'SHOW_PAYMENT_LOGS', // SHOW_PAYMENT_LOGS
                    },
                    {
                        title: getTranslate('cardtransactions'),
                        path: PATH_MANAGE.card_transactions,
                        permission: 'SHOW_CARD_TRANSACTIONS_LOGS', // SHOW_CARD_TRANSACTIONS_LOGS
                    },
                ],
            },
        ],
    },
    {
        subheader: getTranslate('management'),
        permission: 'SHOW_MANAGEMENTS',
        items: [
            {
                title: getTranslate('employees'),
                path: PATH_MANAGE.employees,
                icon: ICONS.group,
                permission: 'READ_EMPLOYEES', // READ_EMPLOYEES
                children: [
                    {
                        title: getTranslate('employee_manage'),
                        path: PATH_MANAGE.employee_manage,
                        permission: 'MANAGE_EMPLOYEE', // MANAGE_EMPLOYEE
                    },
                    {
                        title: getTranslate('employee_create'),
                        path: PATH_MANAGE.employee_create,
                        permission: 'NEW_EMPLOYEE', // NEW_EMPLOYEE
                    },
                ],
            },
            {
                title: getTranslate('Occasions'),
                path: PATH_MANAGE.occasions,
                icon: ICONS.occasions,
                permission: 'READ_OCCASIONS', // READ_OCCASIONS
                children: [
                    {
                        title: getTranslate('occasion_manage'),
                        path: PATH_MANAGE.occasion_manage,
                        permission: 'MANAGE_OCCASION', // MANAGE_OCCASION
                    },
                    {
                        title: getTranslate('occasion_create'),
                        path: PATH_MANAGE.occasion_create,
                        permission: 'NEW_OCCASION', // NEW_OCCASION
                    },
                ],
            },
            {
                title: getTranslate('products'),
                path: PATH_MANAGE.products,
                icon: ICONS.products,
                permission: 'READ_PRODUCTS', // READ_PRODUCTS
                children: [
                    {
                        title: getTranslate('products_manage'),
                        path: PATH_MANAGE.products_manage,
                        permission: 'MANAGE_PRODUCTS', // MANAGE_PRODUCTS
                    },
                    {
                        title: getTranslate('category_manage'),
                        path: PATH_MANAGE.category_manage,
                        permission: 'MANAGE_CATEGORIES', // MANAGE_CATEGORIES
                    },
                ],
            },
            {
                title: getTranslate('discounts'),
                path: PATH_MANAGE.discounts,
                icon: ICONS.discount,
                permission: 'READ_DISCOUNTS', // READ_DISCOUNTS
                children: [
                    {
                        title: getTranslate('discount_manage'),
                        path: PATH_MANAGE.discount_manage,
                        permission: ' MANAGE_DISCOUNTS', // MANAGE_DISCOUNTS
                    },
                    {
                        title: getTranslate('discount_create'),
                        path: PATH_MANAGE.discount_create,
                        permission: 'NEW_DISCOUNT', // NEW_DISCOUNT
                    },
                ],
            },
            {
                title: getTranslate('offdays'),
                path: PATH_MANAGE.offdays,
                icon: ICONS.offdays,
                permission: 'READ_OFF_DAYS', // READ_OFF_DAYS
                children: [
                    {
                        title: getTranslate('offday_manage'),
                        path: PATH_MANAGE.offday_manage,
                        permission: 'MANAGE_OFF_DAYS', // MANAGE_OFF_DAYS
                    },
                    {
                        title: getTranslate('offday_create'),
                        path: PATH_MANAGE.offday_create,
                        permission: 'NEW_OFF_DAY', // NEW_OFF_DAY
                    },
                ],
            },
            {
                title: getTranslate('departments'),
                path: PATH_MANAGE.departments,
                icon: ICONS.department,
                permission: 'READ_DEPARTMENTS', // READ_DEPARTMENTS
                children: [
                    {
                        title: getTranslate('departments_manage'),
                        path: PATH_MANAGE.department_manage,
                        permission: 'MANAGE_DEPARTMENTS', // MANAGE_DEPARTMENTS
                    },
                    {
                        title: getTranslate('departments_create'),
                        path: PATH_MANAGE.department_create,
                        permission: ' NEW_DEPARTMENT', // NEW_DEPARTMENT
                    },
                ],
            },
            {
                title: getTranslate('teams'),
                path: PATH_MANAGE.teams,
                icon: ICONS.teams,
                permission: 'READ_TEAMS', // READ_TEAMS
                children: [
                    {
                        title: getTranslate('teams_manage'),
                        path: PATH_MANAGE.teams_manage,
                        permission: 'MANAGE_TEAMS', // MANAGE_TEAMS
                    },
                    {
                        title: getTranslate('teams_create'),
                        path: PATH_MANAGE.teams_create,
                        permission: 'NEW_TEAM', // NEW_TEAM
                    },
                ],
            },
            {
                title: getTranslate('roles'),
                path: PATH_MANAGE.roles,
                icon: ICONS.role,
                permission: 'READ_ROLES', // READ_ROLES
                children: [
                    {
                        title: getTranslate('roles_manage'),
                        path: PATH_MANAGE.roles_manage,
                        permission: 'MANAGE_ROLES', // MANAGE_ROLES
                    },
                    {
                        title: getTranslate('roles_create'),
                        path: PATH_MANAGE.roles_create,
                        permission: 'NEW_ROLE', // NEW_ROLE
                    },
                ],
            },

            {
                title: getTranslate('florists_types'),
                path: PATH_MANAGE.florists_types,
                icon: ICONS.florists,
                permission: ' READ_FLORIST_TYPES', // READ_FLORIST_TYPES
                children: [
                    {
                        title: getTranslate('florists_types_manage'),
                        path: PATH_MANAGE.florists_types_manage,
                        permission: 'MANAGE_FLORIST_TYPE', // MANAGE_FLORIST_TYPE
                    },
                    {
                        title: getTranslate('florists_types_create'),
                        path: PATH_MANAGE.florists_types_create,
                        permission: 'CREATE_FLORIST_TYPE', // CREATE_FLORIST_TYPE
                    },
                ],
            },
            // {
            //   title: getTranslate('contacts'),
            //   path: PATH_MANAGE.contacts,
            //   icon: ICONS.contacts,
            //   permission: 'READ_CONTACTS', // READ_CONTACTS
            //   children: [
            //     {
            //       title: getTranslate('contacts_manage'),
            //       path: PATH_MANAGE.contacts_manage,
            //       permission: 'MANAGE_CONTACTS', // MANAGE_CONTACTS
            //     },
            //     {
            //       title: getTranslate('contacts_create'),
            //       path: PATH_MANAGE.contacts_create,
            //       permission: 'CREATE_CONTACTS', // CREATE_CONTACTS
            //     },
            //     {
            //       title: getTranslate('contacts_type_manage'),
            //       path: PATH_MANAGE.contacts_type_manage,
            //       permission: 'MANAGE_CONTACT_TYPES', // MANAGE_CONTACT_TYPES
            //     },
            //     {
            //       title: getTranslate('contacts_type_create'),
            //       path: PATH_MANAGE.contacts_type_create,
            //       permission: 'CREATE_CONTACT_TYPES', // CREATE_CONTACT_TYPES
            //     },
            //   ],
            // },
            {
                title: getTranslate('permissions'),
                path: PATH_MANAGE.permissions,
                icon: ICONS.access,
                permission: 'SHOW_PERMISSIONS', // SHOW_PERMISSIONS
            },
        ],
    },
    // {
    //   subheader: 'EXAMPLES',
    //   items: [
    //     { title: 'analytics', path: PATH_DASHBOARD.general.analytics, icon: ICONS.analytics },
    //     { title: 'banking', path: PATH_DASHBOARD.general.banking, icon: ICONS.banking },
    //     { title: 'booking', path: PATH_DASHBOARD.general.booking, icon: ICONS.booking },
    //     {
    //       title: 'mail',
    //       path: PATH_DASHBOARD.mail.root,
    //       icon: ICONS.mail,
    //       info: (
    //         <Label variant="outlined" color="error">
    //           +32
    //         </Label>
    //       ),
    //     },
    //     {
    //       title: 'user',
    //       path: PATH_DASHBOARD.user.root,
    //       icon: ICONS.user,
    //       children: [
    //         { title: 'profile', path: PATH_DASHBOARD.user.profile },
    //         { title: 'cards', path: PATH_DASHBOARD.user.cards },
    //         { title: 'list', path: PATH_DASHBOARD.user.list },
    //         { title: 'create', path: PATH_DASHBOARD.user.newUser },
    //         { title: 'edit', path: PATH_DASHBOARD.user.editById },
    //         { title: 'account', path: PATH_DASHBOARD.user.account },
    //       ],
    //     },
    //     {
    //       title: 'e-commerce',
    //       path: PATH_DASHBOARD.eCommerce.root,
    //       icon: ICONS.cart,
    //       children: [
    //         { title: 'shop', path: PATH_DASHBOARD.eCommerce.shop },
    //         { title: 'product', path: PATH_DASHBOARD.eCommerce.productById },
    //         { title: 'list', path: PATH_DASHBOARD.eCommerce.list },
    //         { title: 'create', path: PATH_DASHBOARD.eCommerce.newProduct },
    //         { title: 'edit', path: PATH_DASHBOARD.eCommerce.editById },
    //         { title: 'checkout', path: PATH_DASHBOARD.eCommerce.checkout },
    //         { title: 'invoice', path: PATH_DASHBOARD.eCommerce.invoice },
    //       ],
    //     },
    //     {
    //       title: 'blog',
    //       path: PATH_DASHBOARD.blog.root,
    //       icon: ICONS.blog,
    //       children: [
    //         { title: 'posts', path: PATH_DASHBOARD.blog.posts },
    //         { title: 'post', path: PATH_DASHBOARD.blog.postById },
    //         { title: 'new post', path: PATH_DASHBOARD.blog.newPost },
    //       ],
    //     },

    //     { title: 'chat', path: PATH_DASHBOARD.chat.root, icon: ICONS.chat },
    //     { title: 'calendar', path: PATH_DASHBOARD.calendar, icon: ICONS.calendar },
    //     {
    //       title: 'kanban',
    //       path: PATH_DASHBOARD.kanban,
    //       icon: ICONS.kanban,
    //     },
    //   ],
    // },
];

export default navConfig;
