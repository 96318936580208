import { useEffect, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { setLoading, setLoginActive } from 'redux/slices/authSlices';

import { useNavigate } from 'react-router';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Card, Stack, Container, Typography, Alert } from '@mui/material';
// routes
// hooks
import useResponsive from '../../hooks/useResponsive';
// components
import Page from '../../components/Page';
import Logo from '../../components/Logo';
import Image from '../../components/Image';
// sections
import { LoginForm } from '../../sections/auth/login';
import MainLogo from '../../assets/_logos/logo_dark.png';
import RuthsRoses from '../../assets/_logos/ruthsroses.svg';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        display: 'flex',
    },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
    top: 0,
    zIndex: 9,
    lineHeight: 0,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    padding: theme.spacing(3),
    justifyContent: 'space-between',
    [theme.breakpoints.up('md')]: {
        alignItems: 'flex-start',
        padding: theme.spacing(7, 5, 0, 7),
    },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
    width: '100%',
    maxWidth: 464,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Login() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isAuthenticated, errorMessage, loginActive } = useSelector((state) => state.auth);
    const [count, setCount] = useState(getRandomInteger());

    const mdUp = useResponsive('up', 'md');
    const { hostname } = window.location;
    let bigLogo = '';
    if (hostname === 'www.floranet.app') {
        bigLogo = MainLogo;
    } else {
        bigLogo = RuthsRoses;
    }

    function getRandomInteger() {
        const min = Math.ceil(process.env.REACT_APP_LOGIN_MIN);
        const max = Math.floor(process.env.REACT_APP_LOGIN_MAX);
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }
    useEffect(() => {
        dispatch(setLoading());

        if (isAuthenticated) navigate('/main');
    }, [isAuthenticated]); // eslint-disable-line

    useEffect(() => {
        if (!loginActive) {
            if (count > 0) {
                const timer = setTimeout(() => {
                    const counter = count - 1;
                    setCount(counter);
                }, 1000);
                return () => clearTimeout(timer);
            }
            dispatch(setLoginActive(true));
        }
    }, [loginActive, count]);

    return (
        <Page title="Login">
            <RootStyle>
                <HeaderStyle>
                    <Logo />
                </HeaderStyle>
                {mdUp && (
                    <SectionStyle>
                        <Image alt="login" src={bigLogo} />
                    </SectionStyle>
                )}
                <Container maxWidth="sm">
                    <ContentStyle>
                        <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
                            <Box sx={{ flexGrow: 1 }}>
                                <Typography variant="h3" gutterBottom>
                                    Login
                                </Typography>
                            </Box>
                        </Stack>
                        {errorMessage && (
                            <Alert severity="error" sx={{ mb: 3 }}>
                                <strong> {errorMessage}</strong>
                            </Alert>
                        )}
                        <LoginForm />
                        {!loginActive && (
                            <Alert severity="error" sx={{ mt: 3 }}>
                                <strong>Please wait {count} seconds to log in.</strong>
                            </Alert>
                        )}
                    </ContentStyle>
                </Container>
            </RootStyle>
        </Page>
    );
}
