import PropTypes from 'prop-types';
import { useEffect, useRef, useMemo } from 'react';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { TextField } from '@mui/material';

// ----------------------------------------------------------------------

RHFTextFieldGoogleAutoCompleted.propTypes = {
    name: PropTypes.string,
    helperText: PropTypes.node,
    setField: PropTypes.func.isRequired,
    cityFieldName: PropTypes.string.isRequired,
    zipcodeFieldName: PropTypes.string.isRequired,
    stateFieldName: PropTypes.string.isRequired,
    setSelectedState: PropTypes.func,
};

export default function RHFTextFieldGoogleAutoCompleted({
    name,
    helperText,
    setField,
    cityFieldName,
    zipcodeFieldName,
    stateFieldName,
    setSelectedState,
    ...other
}) {
    const { control } = useFormContext();
    const autoCompleteRef = useRef();
    const inputRef = useRef();
    const options = useMemo(
        () => ({
            componentRestrictions: { country: 'us' },
            language: 'en',
        }),
        []
    );

    useEffect(() => {
        autoCompleteRef.current = new window.google.maps.places.Autocomplete(inputRef.current, options);
        autoCompleteRef.current.addListener('place_changed', async () => {
            const place = await autoCompleteRef.current.getPlace();
            // setField(name, place?.formatted_address);
            setField(name, place?.name);

            const filteredArrayCity = place?.address_components?.filter((item) => item.types.includes('locality'));
            setField(cityFieldName, filteredArrayCity[0]?.long_name);

            const filteredArrayZipcode = place?.address_components?.filter((item) =>
                item.types.includes('postal_code')
            );
            const filteredArrayState = place?.address_components?.filter((item) =>
                item.types.includes('administrative_area_level_1')
            );
            console.log('filteredArrayState', filteredArrayState);
            setField(zipcodeFieldName, filteredArrayZipcode[0]?.long_name);

            setField(stateFieldName, filteredArrayState[0]?.short_name);
            if (stateFieldName === 'billing_state') {
                setSelectedState(filteredArrayState[0]?.short_name);
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputRef, options]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Enter') {
                event.preventDefault();
                event.stopPropagation();
            }
        };

        const inputElement = inputRef.current;

        if (inputElement) {
            inputElement.addEventListener('keydown', handleKeyDown);
        }

        return () => {
            if (inputElement) {
                inputElement.removeEventListener('keydown', handleKeyDown);
            }
        };
    }, []);

    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => (
                <TextField
                    inputRef={inputRef}
                    {...field}
                    fullWidth
                    value={typeof field.value === 'number' && field.value === 0 ? '' : field.value}
                    error={!!error}
                    helperText={error ? error?.message : helperText}
                    {...other}
                />
            )}
        />
    );
}
