import { Box, Button, CircularProgress, Grid, Typography } from '@components';

const CompletedBox = ({ title, count, isLoading, selectedBox, setSelectedBox = () => {}, disabled = false }) => (
  <Button
    variant="standart"
    disabled={disabled}
    sx={{
      minWidth: 180,
      width: 220,
      // height: 65,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      border: '.1px solid #000',
      borderRadius: 1,
      margin: 0.3,
      backgroundColor: `${selectedBox === title ? '#CEFA055e' : 'white'}`,
      padding: '4px 8px',
    }}
    onClick={() => {
      // getValues(status);

      setSelectedBox(title);
    }}
  >
    <Box>
      <Grid container spacing={1} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <Grid
          item
          xs={12}
          sx={{ whiteSpace: 'pre-line', textAlign: 'center', fontSize: 14, lineHeight: '1rem', color: '#00AB55' }}
        >
          {title}
        </Grid>
        <Grid item xs={12} sx={{ textAlign: 'center' }}>
          <Typography variant="h3" color={'#00AB55'}>
            {isLoading ? <CircularProgress size={28} color="primary"></CircularProgress> : count}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography
            variant="body1"
            color={'black'}
            sx={{ display: 'flex', alignItems: 'center', fontWeight: 'bold' }}
          ></Typography>
        </Grid>
      </Grid>
    </Box>
  </Button>
);

export default CompletedBox;
