import axios from 'utils/axios';

class ReportsService {
    getBankTotalTransactions = (starttime, endtime) =>
        new Promise((resolve, reject) => {
            axios
                .get(`api/reports/getBankTotalTransactions?starttime=${starttime}&endtime=${endtime}`)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((err) => {
                    reject(err.message);
                });
        });

    getBankTransactions = (starttime, endtime, bankId) =>
        new Promise((resolve, reject) => {
            axios
                .get(`api/reports/getBankTransactions?starttime=${starttime}&endtime=${endtime}&bankId=${bankId}`)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((err) => {
                    reject(err.message);
                });
        });

    getDailyReports = (starttime, endtime) =>
        new Promise((resolve, reject) => {
            axios
                .get(`api/reports/getDailyReports?starttime=${starttime}&endtime=${endtime}`)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((err) => {
                    reject(err.message);
                });
        });

    getTagsTotalResults = (starttime, endtime) =>
        new Promise((resolve, reject) => {
            axios
                .get(`api/reports/getTagsTransactions?starttime=${starttime}&endtime=${endtime}`)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((err) => {
                    reject(err.message);
                });
        });

    getVendorTotalResults = (starttime, endtime) =>
        new Promise((resolve, reject) => {
            axios
                .get(`api/reports/getVendorsTotalTransactions?starttime=${starttime}&endtime=${endtime}`)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((err) => {
                    reject(err.message);
                });
        });

    getDailyReportsFiltering = (starttime, endtime, domain) =>
        new Promise((resolve, reject) => {
            let apiUrl = `api/reports/getDailyReports?starttime=${starttime}&endtime=${endtime}`;

            if (domain !== 'All') {
                apiUrl += `&projectDomainPrefix=${domain}`;
            }

            axios
                .get(apiUrl)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((err) => {
                    reject(err.message);
                });
        });

    getTagTransactions = (tag, starttime, endtime, domain, paymentCardNumber) =>
        new Promise((resolve, reject) => {
            let query = '';
            if (tag !== null) {
                query += `&tagName=${tag}`;
            }
            if (domain !== 'All') {
                query += `&projectDomainPrefix=${domain}`;
            }
            if (paymentCardNumber) {
                query += `&paymentCardNumber=${paymentCardNumber}`;
            }
            axios
                .get(`api/reports/getTagTransactions?starttime=${starttime}&endtime=${endtime}${query}`)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((err) => {
                    reject(err.message);
                });
        });

    getTagTransactionsProfit = (tag, starttime, endtime) =>
        new Promise((resolve, reject) => {
            let query = '';
            if (tag !== null) {
                query += `&tagName=${tag}`;
            }
            axios
                .get(`api/reports/getTagTransactions?starttime=${starttime}&endtime=${endtime}${query}`)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((err) => {
                    reject(err.message);
                });
        });

    getOrdersWithCreditCardType = (starttime, endtime, orderTagEnum, creditCardTypeEnum) =>
        new Promise((resolve, reject) => {
            let query = '';
            if (orderTagEnum !== null) {
                query += `&orderTagEnum=${orderTagEnum}`;
            }

            if (creditCardTypeEnum !== null) {
                query += `&creditCardTypeEnum=${creditCardTypeEnum}`;
            }

            axios
                .get(
                    `api/reports/getTagTransactions?starttime=${starttime}&endtime=${endtime}&reportType=reportCreditCard${query}`
                )
                .then((response) => {
                    resolve(response.data);
                })
                .catch((err) => {
                    reject(err.message);
                });
        });

    getOrdersWithOrderTag = (starttime, endtime, orderTagEnum) =>
        new Promise((resolve, reject) => {
            let query = '';
            if (orderTagEnum !== null) {
                query += `&orderTagEnum=${orderTagEnum}`;
            }

            axios
                .get(
                    `api/reports/getTagTransactions?starttime=${starttime}&endtime=${endtime}&reportType=reportCreditCard${query}`
                )
                .then((response) => {
                    resolve(response.data);
                })
                .catch((err) => {
                    reject(err.message);
                });
        });

    getOrderSourceTransactions = (tag, starttime, endtime, domain) =>
        new Promise((resolve, reject) => {
            let query = '';

            if (tag !== null) {
                query += `&orderSource=${tag}`;
            }
            if (domain !== 'All' && domain !== undefined && domain !== null) {
                query += `&projectDomainPrefix=${domain}`;
            }
            axios
                .get(`api/reports/getTagTransactions?starttime=${starttime}&endtime=${endtime}${query}`)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((err) => {
                    reject(err.message);
                });
        });

    getTransactionsBank = (tag, starttime, endtime) =>
        new Promise((resolve, reject) => {
            let query = '';
            if (tag !== null) {
                query += `&bankId=${tag}`;
            }
            axios
                .get(`api/reports/getBankTransactions?starttime=${starttime}&endtime=${endtime}${query}`)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((err) => {
                    reject(err.message);
                });
        });

    getOccasionTransactions = (tag, starttime, endtime, domainPrefix) =>
        new Promise((resolve, reject) => {
            let query = '';
            if (tag !== null) {
                query += `&occasionId=${tag}`;
            }
            if (domainPrefix !== 'All') {
                query += `&projectDomainPrefix=${domainPrefix}`;
            }
            axios
                .get(`api/reports/getOccasionTransactions?starttime=${starttime}&endtime=${endtime}${query}`)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((err) => {
                    reject(err.message);
                });
        });

    getVendorTransactions = (tag, starttime, endtime) =>
        new Promise((resolve, reject) => {
            let query = '';
            if (tag !== null) {
                query += `&vendorId=${tag}`;
            }
            axios
                .get(`api/reports/getVendorsTransactions?starttime=${starttime}&endtime=${endtime}${query}`)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((err) => {
                    reject(err.message);
                });
        });

    getTimeZoneTransactions = (tag, starttime, endtime, domain) =>
        new Promise((resolve, reject) => {
            let query = '';
            if (tag !== null) {
                query += `&customerTimezone=${tag}`;
            }
            if (domain !== 'All') {
                query += `&projectDomainPrefix=${domain}`;
            }
            axios
                .get(`api/reports/getTimezonesTransactions?starttime=${starttime}&endtime=${endtime}${query}`)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((err) => {
                    reject(err.message);
                });
        });

    getAllOrdersTransactions = (starttime, endtime, domain) =>
        new Promise((resolve, reject) => {
            let query = '';

            if (domain !== 'All' && domain !== undefined && domain !== null) {
                query += `&projectDomainPrefix=${domain}`;
            }
            axios
                .get(
                    `api/reports/getCreditCardTypesTotalTransactions?starttime=${starttime}&endtime=${endtime}${query}`
                )
                .then((response) => {
                    resolve(response.data);
                })
                .catch((err) => {
                    reject(err.message);
                });
        });

    getCompletedOrdersTransactions = (starttime, endtime, domain) =>
        new Promise((resolve, reject) => {
            let query = '';

            if (domain !== 'All' && domain !== undefined && domain !== null) {
                query += `&projectDomainPrefix=${domain}`;
            }
            axios
                .get(
                    `api/reports/getCreditCardTypesTotalTransactions?starttime=${starttime}&endtime=${endtime}&orderTag=completed${query}`
                )
                .then((response) => {
                    resolve(response.data);
                })
                .catch((err) => {
                    reject(err.message);
                });
        });

    getUncompletedOrdersTransactions = (starttime, endtime, domain) =>
        new Promise((resolve, reject) => {
            let query = '';

            if (domain !== 'All' && domain !== undefined && domain !== null) {
                query += `&projectDomainPrefix=${domain}`;
            }
            axios
                .get(
                    `api/reports/getCreditCardTypesTotalTransactions?starttime=${starttime}&endtime=${endtime}&orderTag=uncompleted${query}`
                )
                .then((response) => {
                    resolve(response.data);
                })
                .catch((err) => {
                    reject(err.message);
                });
        });
}

const instance = new ReportsService();

export default instance;
