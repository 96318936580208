import axios from 'utils/axios';

class FloristsReportService {
    getResults = (state, starttime, endtime, floristName, zipcode) =>
        new Promise((resolve, reject) => {
            let query = '';
            if (state !== null && state.toLowerCase() !== 'all') {
                query += `&deliveryState=${state}`;
            }
            axios
                .get(
                    `api/orders/getOrderFromSearch?starttime=${starttime}&endtime=${endtime}&floristName=${floristName}&deliveryZipcode=${zipcode}${query}`
                )
                .then((response) => {
                    resolve(response.data);
                })
                .catch((err) => {
                    reject(err.message);
                });
        });
}

const instance = new FloristsReportService();

export default instance;
