import React from 'react';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';
import { useFormContext, Controller } from 'react-hook-form';
import { TextField } from '@mui/material';

RHFTextFieldMask.propTypes = {
    name: PropTypes.string,
    helperText: PropTypes.node,
    mask: PropTypes.string,
};

export default function RHFTextFieldMask({ name, helperText, mask, ...other }) {
    const { control } = useFormContext();

    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => (
                <InputMask mask={mask} value={field.value} onChange={field.onChange}>
                    {(inputProps) => (
                        <TextField
                            {...inputProps}
                            fullWidth
                            value={typeof field.value === 'number' && field.value === 0 ? '' : field.value}
                            error={!!error}
                            helperText={error ? error?.message : helperText}
                            {...other}
                        />
                    )}
                </InputMask>
            )}
        />
    );
}
