import Page from 'components/Page';
import { RangeDateInput, endOfDay, startOfDay } from '@components';
import { Autocomplete, Container, Grid, IconButton, TextField, Tooltip, Box, Button } from '@mui/material';
import { format } from 'date-fns';
import i18n, { t } from 'i18next';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import * as XLSX from 'xlsx';

import Iconify from 'components/Iconify';
import StatusFinder from 'components/StatusFinder';
import TableCustomized, { TableCustomizedNew } from 'components/TableCustomized';
import useSettings from 'hooks/useSettings';
import FloristsReportService from 'services/floristsReportService';

import DownloadIcon from '@mui/icons-material/Download';

import { capitalizeFirstLetter } from 'utils/capitalizeFirstLetter';
import { formatNoteText } from 'utils/formatNoteText';

import en from './i18n/en.json';

i18n.addResourceBundle('en', 'florists_reports', en);

export default function FloristsReports() {
    const { states } = useSelector((state) => state.orders);
    const { themeStretch } = useSettings();

    const [selectedState, setSelectedState] = useState({
        code: 'All',
        id: 0,
        title: 'All',
    });
    const [rows, setRows] = useState([]);
    const [starttime, setStarttime] = useState(startOfDay(new Date()).getTime());
    const [endtime, setEndtime] = useState(endOfDay(new Date()).getTime());
    const [zipcode, setZipcode] = useState('');
    const [floristName, setFloristName] = useState('');
    // const [searchClicked, setSearchClicked] = useState(false);
    const [pageLoading, setIsPageLoading] = useState(false);
    const [headCells] = useState([
        {
            id: 'order_id',
            align: 'left',
            disablePadding: false,
            label: t('florists_reports:headCells.order_id'),
            short: 'order_id',
        },
        {
            id: 'floristName',
            align: 'left',
            disablePadding: false,
            label: t('florists_reports:headCells.florist_name'),
            short: 'order_id',
            component: (row) => <Box>{row.order_details[0]?.details?.fullfill?.floristName}</Box>,
        },
        {
            id: 'rec_firstname',
            align: 'center',
            disablePadding: false,
            label: t('florists_reports:headCells.firstname'),
            short: 'rfirstName',
            component: (row) => <Box>{row.firstName}</Box>,
        },
        {
            id: 'rec_lastname',
            align: 'center',
            disablePadding: false,
            label: t('florists_reports:headCells.lastname'),
            short: 'rlastName',
            component: (row) => <Box>{row.lastName}</Box>,
        },
        {
            id: 'created_at',
            align: 'center',
            disablePadding: false,
            label: t('florists_reports:headCells.created_at'),
            short: 'created_at',
            component: (row) => <Box>{format(new Date(row.created_at), 'MM-dd-yyyy h:mm a')}</Box>,
        },
        {
            id: 'order_tag',
            align: 'center',
            disablePadding: false,
            label: t('florists_reports:headCells.order_status'),
            short: 'order_tag',
            component: (row) => <StatusFinder>{row.order_tag}</StatusFinder>,
        },
        {
            id: 'zipcode',
            align: 'center',
            disablePadding: false,
            label: t('florists_reports:headCells.zipcode'),
            short: 'details.zipcode',
            component: (row) => <Box>{row.order_details[0]?.delivery?.zipcode}</Box>,
        },
        {
            id: 'state',
            align: 'center',
            disablePadding: false,
            label: t('florists_reports:headCells.state'),
            short: 'details.state',
            component: (row) => <Box>{row.order_details[0]?.delivery?.state}</Box>,
        },
    ]);

    const getAllStateinfo = async () => {
        setIsPageLoading(true);
        const data = await FloristsReportService.getResults(
            selectedState.code,
            starttime,
            endtime,
            floristName,
            zipcode
        );

        const newData = data.map((order) => ({
            ...order,
            firstName: formatNoteText(capitalizeFirstLetter(order.order_details[0].delivery.firstname)),
            lastName: formatNoteText(capitalizeFirstLetter(order.order_details[0].delivery.lastname)),
        }));
        setRows(newData);
        setIsPageLoading(false);
    };

    const downloadExcel = () => {
        const worksheetData = rows.map((row) => ({
            'Order ID': row.order_id,
            'Florist Name': row.order_details[0]?.details?.fullfill?.floristName,
            'Recipient First Name': row.order_details[0].delivery.firstname,
            'Recipient Last Name': row.order_details[0].delivery.lastname,
            'Created At': format(new Date(row.created_at), 'MM-dd-yyyy h:mm a').toString(),
            'Order Status': row.order_tag,
            'Recipient Zipcode': row.order_details[0]?.delivery?.zipcode,
            'Recipient State': row.order_details[0]?.delivery?.state,
        }));

        const workbook = {
            Sheets: {
                data: XLSX.utils.json_to_sheet(worksheetData),
            },
            SheetNames: ['data'],
        };

        const filename = `florists_reports_${format(new Date(), 'yyyyMMddHHmmss')}.xlsx`;
        XLSX.writeFile(workbook, filename);
    };

    // useEffect(() => {
    //     if (searchClicked) {
    //         getAllStateinfo();
    //         setSearchClicked(false);
    //     }
    // }, [searchClicked]);
    const handleSearch = () => {
        getAllStateinfo();
    };
    return (
        <Page loading={pageLoading}>
            <Container maxWidth={themeStretch ? false : 'xl'}>
                <Box sx={{ width: '100%' }}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    marginBottom: 2,
                                    flexWrap: 'wrap',
                                    gap: '4px',
                                }}
                            >
                                <Autocomplete
                                    value={selectedState}
                                    onChange={(event, value) =>
                                        setSelectedState(value || { code: 'All', id: 0, title: 'All' })
                                    }
                                    sx={{ width: 120, marginRight: 2 }}
                                    size="small"
                                    getOptionLabel={(option) => option.title}
                                    isOptionEqualToValue={(option, value) => option.code === value.code}
                                    options={states}
                                    renderInput={(params) => <TextField {...params} label="Select State" />}
                                />
                                <TextField
                                    label="Zipcode"
                                    variant="outlined"
                                    size="small"
                                    value={zipcode}
                                    onChange={(e) => setZipcode(e.target.value)}
                                    sx={{ marginRight: 2, width: 100 }}
                                />
                                <TextField
                                    label="Florist Name"
                                    variant="outlined"
                                    size="small"
                                    value={floristName}
                                    onChange={(e) => setFloristName(e.target.value)}
                                    sx={{ marginRight: 2 }}
                                />
                                <RangeDateInput
                                    startdate={starttime}
                                    enddate={endtime}
                                    setStartDate={setStarttime}
                                    setEndDate={setEndtime}
                                    sx={{ marginRight: 2 }}
                                    hidesearch
                                />
                                <Button
                                    sx={{ borderRadius: 0, marginX: 0.5, padding: '8px 12px' }}
                                    variant="contained"
                                    color="success"
                                    startIcon={<Iconify icon="charm:search" />}
                                    onClick={handleSearch}
                                >
                                    Search
                                </Button>
                                <Tooltip title="Export to Excel">
                                    <IconButton
                                        color="info"
                                        variant={'contained'}
                                        sx={{ borderRadius: 0, border: '1px solid #1890FF', marginX: 0.5 }}
                                        onClick={downloadExcel}
                                    >
                                        <DownloadIcon />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                            <TableCustomized rows={rows} headCells={headCells} />
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </Page>
    );
}
