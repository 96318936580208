import { Box, Grid, IconButton } from '@mui/material';
import Iconify from 'components/Iconify';
import Page from 'components/Page';
import { TableCustomizedNew } from 'components/TableCustomized';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import ReportsService from 'services/reportsService';

const AgentDetailReports = () => {
  const location = useLocation();
  const starttime = location.state.stime;
  const endtime = location.state.etime;
  const { agentName } = location.state;
  const { orderSource } = location.state;
  const navigate = useNavigate();

  const [rows, setRows] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [pageLoading, setIsPageLoading] = useState(true);

  const { states } = useSelector((state) => state.orders);

  const headCells = [
    {
      id: 'order_id',
      align: 'left',
      disablePadding: false,
      label: 'Order Status',
      short: 'order_status',
      component: (row) => (
        <Box>
          <Grid container>
            <Grid item xs={12}>
              {row.order_id}
            </Grid>
          </Grid>
        </Box>
      ),
    },
    {
      id: 'order_status',
      align: 'left',
      disablePadding: false,
      label: 'Order Status',
      short: 'order_status',
      component: (row) => (
        <Box>
          <Grid container>
            <Grid item xs={12}>
              {row.order_tag}
            </Grid>
          </Grid>
        </Box>
      ),
    },
    {
      id: 'agent',
      align: 'center',
      disablePadding: false,
      label: t('headCells.agent'),
      short: 'details.agent',
      component: (row) => (
        <Box>
          <Grid container>
            <Grid item xs={12}>
              {row?.details.agent}
            </Grid>
          </Grid>
        </Box>
      ),
    },

    {
      id: 'margin',
      align: 'center',
      disablePadding: false,
      label: t('headCells.salesMargin'),
      short: 'details.margin',
      component: (row) => (
        <Box>
          <Grid container>
            <Grid item xs={12}>
              <Box sx={{ padding: 0.1 }}>
                <Box>{row?.details.margin} %</Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      ),
    },
    {
      id: 'total_price',
      align: 'center',
      disablePadding: false,
      label: t('headCells.totalPrice'),
      short: 'details.total_price',
      component: (row) => (
        <Box>
          <Grid container>
            <Grid item xs={12}>
              <Box sx={{ padding: 0.1 }}>
                <Box>$ {row?.details.total_price}</Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      ),
    },
    {
      id: 'state',
      align: 'center',
      disablePadding: false,
      label: t('headCells.state'),
      short: 'stateName',
      component: (row) => (
        <Box>
          <Grid container>
            <Grid item xs={12}>
              <Box sx={{ padding: 0.1 }}>
                <Box> {row?.stateName}</Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      ),
    },
    {
      id: 'show',
      align: 'center',
      disablePadding: false,
      label: t('headCells.preview'),
      component: (row) => (
        <Box>
          <IconButton
            onClick={() => {
              navigate(`/main/orders/order/${row.order_id}`);
            }}
          >
            <Iconify icon={'bx:show-alt'}></Iconify>
          </IconButton>
        </Box>
      ),
    },
  ];

  const getOrderValues = async () => {
    const data = await ReportsService.getOrderSourceTransactions(orderSource, starttime, endtime);
    setIsPageLoading(false);
    const filteredData = data.filter(
      (order) =>
        order.details.agent !== undefined && order.details.agent !== null && order.details.agent !== 'web order'
    );

    setRows(filteredData);
    setIsPageLoading(false);
  };

  useEffect(() => {
    getOrderValues();
  }, [agentName]);

  useEffect(() => {
    let unmounted = false;
    const agentData = rows.filter((order) => order.details.agent === agentName);
    const customData = [];
    agentData.forEach((order) => {
      const stateName = states.find((item) => item.code === order?.order_details[0]?.delivery?.state);
      customData.push({
        ...order,
        stateName: stateName.title,
      });
    });

    customData.forEach((order) => {
      order.details.total_price = parseFloat(order.details.total_price);
    });

    customData.forEach((order) => {
      order.details.margin = parseFloat(order.details.margin);
    });
    setFilteredData(customData);

    return () => {
      unmounted = true;
    };
  }, [rows]);

  return (
    <Page loading={pageLoading}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TableCustomizedNew
            align="right"
            rows={filteredData}
            headCells={headCells}
            perpage={10}
            rowsperpage={[10, 25, 50]}
          >
            {/* <Toolbar sx={{ display: 'flex', justifyContent: 'end' }}>
              <>
                <RangeDateInput
                  startdate={starttime}
                  enddate={endtime}
                  setStartDate={setStarttime}
                  setEndDate={setEndtime}
                  // onSubmit={() => {
                  //   getAllDailyinfo();
                  // }}
                ></RangeDateInput>
              </>
            </Toolbar> */}
          </TableCustomizedNew>
        </Grid>
      </Grid>
    </Page>
  );
};

export default AgentDetailReports;
