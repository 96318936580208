import {
  AlertTitle,
  Snackbar,
  ButtonBase,
  Switch,
  FormGroup,
  CardHeader,
  RadioGroup,
  Radio,
  FormLabel,
  FormControlLabel,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  InputAdornment,
  Stack,
  Toolbar,
  Container,
  Paper,
  Box,
  Grid,
  Divider,
  Typography,
  Button,
  Table,
  TableCell,
  TableRow,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  useTheme,
  styled,
  IconButton,
  Tooltip,
  Card,
  CardContent,
  CardActions,
  Autocomplete,
  CircularProgress,
  TextField,
  Alert,
  LinearProgress,
  Checkbox,
} from '@mui/material';

import {
  red,
  blue,
  teal,
  green,
  purple,
  orange,
  indigo,
  lime,
  lightGreen,
  amber,
  yellow,
  blueGrey,
} from '@mui/material/colors';

import TableCustomized from 'components/TableCustomized';

import RangeDateInput from 'components/RangeDateInput';

import Modal from 'components/Modal';
import { DialogAnimate } from 'components/animate';
import Iconify from 'components/Iconify';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { useState, useEffect, useContext, useRef } from 'react';

import { startOfDay, endOfDay, format } from 'date-fns';

const AlertMessage = ({ onClose = () => {}, severity = 'error', children }) => (
  <Alert severity={severity} sx={{ position: 'relative' }}>
    {children}
    <IconButton
      onClick={() => {
        onClose();
      }}
      sx={{ padding: 0, color: red[900], position: 'absolute', right: 8 }}
    >
      <Iconify icon={'ep:circle-close'}></Iconify>
    </IconButton>
  </Alert>
);

const colors = [
  red[400],
  blue[400],
  teal[400],
  green[400],
  purple[400],
  orange[400],
  indigo[400],
  amber[400],
  lime[400],
  lightGreen[400],
  yellow[400],
  blueGrey[400],
];

const stateClear = (obj) => {
  const newObj = { ...obj };
  Object.keys(newObj).map((item) => {
    newObj[item] = '';
    return item;
  });

  return newObj;
};

const categories = [
  {
    'whats-new': {
      name: "What's New",
      catid: 'cat480009',
    },
  },
  {
    'most-popular': {
      name: 'Most Popular Sympathy',
      catid: 'cat4800092',
    },
  },
  {
    '$80+-luxury': {
      name: '$80+ - luxury',
      catid: 'cat210138',
    },
  },
  {
    'red-flowers': {
      name: 'Red Flowers',
      catid: 'cat210176',
    },
  },
  {
    'valentine-roses': {
      name: 'Valentine Roses',
      catid: 'cat210021',
    },
  },
  {
    'for-her': {
      name: 'For Her',
      catid: 'cat210014',
    },
  },
  {
    'orange-flowers': {
      name: 'Orange Flowers',
      catid: 'cat210173',
    },
  },
  {
    '$60-$80-mid-priced': {
      name: '$60-$80 - mid-priced',
      catid: 'cat210137',
    },
  },
  {
    'photo-urn-tributes': {
      name: 'Photo & Urn Tributes',
      catid: 'cat480017',
    },
  },
  {
    'standing-sprays-wreaths': {
      name: 'Standing Sprays & Wreaths',
      catid: 'cat210122',
    },
  },
  {
    'tropical-funeral-flowers': {
      name: 'Tropical Funeral Flowers',
      catid: 'cat210220',
    },
  },
  {
    'all-funeral-service-flowers': {
      name: 'All Funeral Service Flowers',
      catid: 'cat210120',
    },
  },
  {
    hanukkah: {
      name: 'Hanukkah',
      catid: 'cat210080',
    },
  },
  {
    centerpieces: {
      name: 'Centerpieces',
      catid: 'cat210064',
    },
  },
  {
    'hostess-gifts': {
      name: 'Hostess Gifts',
      catid: 'cat670001',
    },
  },
  {
    'white-flowers': {
      name: 'White Flowers',
      catid: 'cat210177',
    },
  },
  {
    'winter-flowers': {
      name: 'Winter Flowers',
      catid: 'cat210193',
    },
  },
  {
    'holiday-home-decor': {
      name: 'Holiday Home Decor',
      catid: 'cat670002',
    },
  },
  {
    'collectibles-keepsakes': {
      name: 'Collectibles & Keepsakes',
      catid: 'cat210051',
    },
  },
  {
    'silver-gold-christmas-flowers': {
      name: 'Silver & Gold Christmas Flowers',
      catid: 'cat1070002',
    },
  },
  {
    easter: {
      name: 'Easter',
      catid: 'cat210067',
    },
  },
  {
    lilies: {
      name: 'Lilies',
      catid: 'cat210153',
    },
  },
  {
    'for-him': {
      name: 'For Him',
      catid: 'cat210041',
    },
  },
  {
    'for-kids': {
      name: 'For Kids',
      catid: 'cat210059',
    },
  },
  {
    graduation: {
      name: 'Graduation',
      catid: 'cat210077',
    },
  },
  {
    quinceaera: {
      name: 'Quinceañera',
      catid: 'cat210060',
    },
  },
  {
    'fathers-day': {
      name: "Father's Day",
      catid: 'cat210071',
    },
  },
  {
    'parents-day': {
      name: 'Parents Day',
      catid: 'cat210102',
    },
  },
  {
    'for-a-friend': {
      name: 'For A Friend',
      catid: 'cat480075',
    },
  },
  {
    'yellow-flowers': {
      name: 'Yellow Flowers',
      catid: 'cat210178',
    },
  },
  {
    'for-grandmother': {
      name: 'For Grandmother',
      catid: 'cat480076',
    },
  },
  {
    'st.-patricks-day': {
      name: "St. Patrick's Day",
      catid: 'cat210107',
    },
  },
  {
    'florist-delivered': {
      name: 'Florist Delivered',
      catid: 'cat610008',
    },
  },
  {
    'same-day-delivery': {
      name: 'Same Day Delivery',
      catid: 'cat480085',
    },
  },
  {
    '$40-$60-inexpensive': {
      name: '$40-$60 - inexpensive',
      catid: 'cat210136',
    },
  },
  {
    'mexican-mothers-day': {
      name: "Mexican Mother's Day",
      catid: 'cat210095',
    },
  },
  {
    'international-womens-day': {
      name: "International Women's Day",
      catid: 'cat210084',
    },
  },
  {
    'sending-flowers-in-the-us': {
      name: 'Sending Flowers in the US',
      catid: 'cat610011',
    },
  },
  {
    'all-sympathy': {
      name: 'All Sympathy',
      catid: 'cat210121',
    },
  },
  {
    'mercury-glass': {
      name: 'Mercury Glass',
      catid: 'cat480032',
    },
  },
  {
    'white-funeral': {
      name: 'White Funeral',
      catid: 'cat570001',
    },
  },
  {
    'sympathy-bouquets': {
      name: 'Sympathy Bouquets',
      catid: 'cat210130',
    },
  },
  {
    'june-pearl-birthstone': {
      name: 'June Pearl Birthstone',
      catid: 'cat210202',
    },
  },
  {
    'october-opal-birthstone': {
      name: 'October Opal Birthstone',
      catid: 'cat210206',
    },
  },
  {
    daisies: {
      name: 'Daisies',
      catid: 'cat210146',
    },
  },
  {
    'im-sorry': {
      name: "I'm Sorry",
      catid: 'cat210082',
    },
  },
  {
    'memorial-day': {
      name: 'Memorial Day',
      catid: 'cat210088',
    },
  },
  {
    'friendship-day': {
      name: 'Friendship Day',
      catid: 'cat210072',
    },
  },
  {
    'grandparents-day': {
      name: 'Grandparents Day',
      catid: 'cat210078',
    },
  },
  {
    'from-$29.99-cheap': {
      name: 'From $29.99 - cheap',
      catid: 'cat210134',
    },
  },
  {
    'housewarming-gifts': {
      name: 'Housewarming Gifts',
      catid: 'cat210081',
    },
  },
  {
    'womens-equality-day': {
      name: "Women's Equality Day",
      catid: 'cat440220',
    },
  },
  {
    'under-$40-low-priced': {
      name: 'Under $40 - low-priced',
      catid: 'cat210135',
    },
  },
  {
    'local-flower-delivery': {
      name: 'Local Flower Delivery',
      catid: 'cat1130012',
    },
  },
  {
    'boston-flower-delivery': {
      name: 'Boston Flower Delivery',
      catid: 'cat1150004',
    },
  },
  {
    'dallas-flower-delivery': {
      name: 'Dallas Flower Delivery',
      catid: 'cat1140002',
    },
  },
  {
    'atlanta-flower-delivery': {
      name: 'Atlanta Flower Delivery',
      catid: 'cat1150002',
    },
  },
  {
    'chicago-flower-delivery': {
      name: 'Chicago Flower Delivery',
      catid: 'cat1140001',
    },
  },
  {
    'houston-flower-delivery': {
      name: 'Houston Flower Delivery',
      catid: 'cat1130016',
    },
  },
  {
    'new-york-flower-delivery': {
      name: 'New York Flower Delivery',
      catid: 'cat1130013',
    },
  },
  {
    'san-jose-flower-delivery': {
      name: 'San Jose Flower Delivery',
      catid: 'cat1150003',
    },
  },
  {
    'teacher-appreciation-week': {
      name: 'Teacher Appreciation Week',
      catid: 'cat210109',
    },
  },
  {
    'washington-d.c.-flower-delivery': {
      name: 'Washington D.C. Flower Delivery',
      catid: 'cat1150001',
    },
  },
  {
    fall: {
      name: 'Fall',
      catid: 'cat210194',
    },
  },
  {
    'orange-roses': {
      name: 'Orange Roses',
      catid: 'cat210160',
    },
  },
  {
    'orange-sympathy': {
      name: 'Orange Sympathy',
      catid: 'cat640006',
    },
  },
  {
    'zen-arrangements': {
      name: 'Zen Arrangements',
      catid: 'cat480010',
    },
  },
  {
    'bamboo-containers': {
      name: 'Bamboo Containers',
      catid: 'cat480034',
    },
  },
  {
    'thanksgiving-(usa)': {
      name: 'Thanksgiving (USA)',
      catid: 'cat210111',
    },
  },
  {
    'for-caskets': {
      name: 'For Caskets',
      catid: 'cat480016',
    },
  },
  {
    'peach-funeral': {
      name: 'Peach Funeral',
      catid: 'cat210214',
    },
  },
  {
    'christmas-flowers': {
      name: 'Christmas Flowers',
      catid: 'cat210045',
    },
  },
  {
    'lavender-roses': {
      name: 'Lavender Roses',
      catid: 'cat210159',
    },
  },
  {
    'purple-flowers': {
      name: 'Purple Flowers',
      catid: 'cat210175',
    },
  },
  {
    'admin-prof.-day': {
      name: 'Admin Prof. Day',
      catid: 'cat210106',
    },
  },
  {
    'lavender-sympathy': {
      name: 'Lavender Sympathy',
      catid: 'cat640009',
    },
  },
  {
    'february-amethyst-birthstone': {
      name: 'February Amethyst Birthstone',
      catid: 'cat210198',
    },
  },
  {
    'pink-flowers': {
      name: 'Pink Flowers',
      catid: 'cat210174',
    },
  },
  {
    sunflowers: {
      name: 'Sunflowers',
      catid: 'cat210167',
    },
  },
  {
    'sweet-16': {
      name: 'Sweet 16',
      catid: 'cat210061',
    },
  },
  {
    carnations: {
      name: 'Carnations',
      catid: 'cat210144',
    },
  },
  {
    chrysanthemum: {
      name: 'Chrysanthemum',
      catid: 'cat210145',
    },
  },
  {
    'order-flowers-in-advance': {
      name: 'Order Flowers in Advance',
      catid: 'cat480084',
    },
  },
  {
    'los-angeles-flower-delivery': {
      name: 'Los Angeles Flower Delivery',
      catid: 'cat1130014',
    },
  },
  {
    'prom:-corsages-amp;-boutonnieres': {
      name: 'Prom: Corsages &amp; Boutonnieres',
      catid: 'cat210104',
    },
  },
  {
    'discount-for-valentine': {
      name: 'Discount for Valentine',
      catid: 'cat570005',
    },
  },
  {
    'pink-roses': {
      name: 'Pink Roses',
      catid: 'cat210161',
    },
  },
  {
    'pink-sympathy': {
      name: 'Pink Sympathy',
      catid: 'cat640002',
    },
  },
  {
    'pink-amp;-purple-funeral': {
      name: 'Pink &amp; Purple Funeral',
      catid: 'cat210216',
    },
  },
  {
    'white-roses': {
      name: 'White Roses',
      catid: 'cat210164',
    },
  },
  {
    'yellow-roses': {
      name: 'Yellow Roses',
      catid: 'cat210163',
    },
  },
  {
    'roses-for-mom': {
      name: 'Roses for Mom',
      catid: 'cat210093',
    },
  },
  {
    'colorful-roses': {
      name: 'Colorful Roses',
      catid: 'cat210158',
    },
  },
  {
    'sweetest-day': {
      name: 'Sweetest Day',
      catid: 'cat210108',
    },
  },
  {
    'green-flowers': {
      name: 'Green Flowers',
      catid: 'cat210172',
    },
  },
  {
    'green-sympathy': {
      name: 'Green Sympathy',
      catid: 'cat640008',
    },
  },
  {
    'may-emerald-birthstone': {
      name: 'May Emerald Birthstone',
      catid: 'cat210201',
    },
  },
  {
    'august-peridot-birthstone': {
      name: 'August Peridot Birthstone',
      catid: 'cat210204',
    },
  },
  {
    gerbera: {
      name: 'Gerbera',
      catid: 'cat210149',
    },
  },
  {
    'for-wife': {
      name: 'For Wife',
      catid: 'cat480080',
    },
  },
  {
    hydrangea: {
      name: 'Hydrangea',
      catid: 'cat210150',
    },
  },
  {
    alstroemeria: {
      name: 'Alstroemeria',
      catid: 'cat210141',
    },
  },
  {
    'couture-vase': {
      name: 'Couture Vase',
      catid: 'cat480036',
    },
  },
  {
    'mixed-bouquets': {
      name: 'Mixed Bouquets',
      catid: 'cat550001',
    },
  },
  {
    'colorful-bouquets': {
      name: 'Colorful Bouquets',
      catid: 'cat210179',
    },
  },
  {
    'blue-flowers': {
      name: 'Blue Flowers',
      catid: 'cat210171',
    },
  },
  {
    orchids: {
      name: 'Orchids',
      catid: 'cat210155',
    },
  },
  {
    tropicals: {
      name: 'Tropicals',
      catid: 'cat210169',
    },
  },
  {
    'yellow-funeral': {
      name: 'Yellow Funeral',
      catid: 'cat210223',
    },
  },
  {
    'purple-amp;-green-funeral': {
      name: 'Purple &amp; Green Funeral',
      catid: 'cat210217',
    },
  },
  {
    'wreaths-trees': {
      name: 'Wreaths & Trees',
      catid: 'cat210046',
    },
  },
  {
    'gift-baskets-plants': {
      name: 'Gift Baskets & Plants',
      catid: 'cat210048',
    },
  },
  {
    'bosses-day': {
      name: 'Bosses Day',
      catid: 'cat210062',
    },
  },
  {
    'back-to-school': {
      name: 'Back to School',
      catid: 'cat210058',
    },
  },
  {
    'seattle-flower-delivery': {
      name: 'Seattle Flower Delivery',
      catid: 'cat1130015',
    },
  },
  {
    'shop-plants': {
      name: 'Shop Plants',
      catid: 'cat210183',
    },
  },
  {
    'warm-colored': {
      name: 'Warm-Colored',
      catid: 'cat210221',
    },
  },
  {
    'funeral-service-bouquets': {
      name: 'Funeral Service Bouquets',
      catid: 'cat210124',
    },
  },
  {
    halloween: {
      name: 'Halloween',
      catid: 'cat210079',
    },
  },
  {
    'white-sympathy': {
      name: 'White Sympathy',
      catid: 'cat640001',
    },
  },
  {
    'table-centerpieces': {
      name: 'Table Centerpieces',
      catid: 'cat550003',
    },
  },
  {
    'blooming-plants': {
      name: 'Blooming Plants',
      catid: 'cat210185',
    },
  },
  {
    'sympathy-plants': {
      name: 'Sympathy Plants',
      catid: 'cat210129',
    },
  },
  {
    'flower-gift-baskets': {
      name: 'Flower Gift Baskets',
      catid: 'cat580005',
    },
  },
  {
    'sympathy-floral-baskets': {
      name: 'Sympathy Floral Baskets',
      catid: 'cat210131',
    },
  },
  {
    'veterans-day': {
      name: 'Veterans Day',
      catid: 'cat470015',
    },
  },
  {
    'patriotic-funeral-flowers': {
      name: 'Patriotic Funeral Flowers',
      catid: 'cat210213',
    },
  },
  {
    snapdragon: {
      name: 'Snapdragon',
      catid: 'cat210166',
    },
  },
  {
    'yellow-sympathy': {
      name: 'Yellow Sympathy',
      catid: 'cat640003',
    },
  },
  {
    'thanksgiving-(canada)': {
      name: 'Thanksgiving (Canada)',
      catid: 'cat210112',
    },
  },
  {
    'red-roses': {
      name: 'Red Roses',
      catid: 'cat210162',
    },
  },
  {
    'july-ruby-birthstone': {
      name: 'July Ruby Birthstone',
      catid: 'cat210203',
    },
  },
  {
    'january-garnet-birthstone': {
      name: 'January Garnet Birthstone',
      catid: 'cat210197',
    },
  },
  {
    'bestsellers-for-valentine': {
      name: 'Bestsellers for Valentine',
      catid: 'cat210264',
    },
  },
  {
    'cube-vase': {
      name: 'Cube Vase',
      catid: 'cat480035',
    },
  },
  {
    'rosh-hashanah': {
      name: 'Rosh Hashanah',
      catid: 'cat210105',
    },
  },
  {
    'calla-lily': {
      name: 'Calla Lily',
      catid: 'cat210143',
    },
  },
  {
    'april-diamond-birthstone': {
      name: 'April Diamond Birthstone',
      catid: 'cat210200',
    },
  },
  {
    'march-aquamarine-birthstone': {
      name: 'March Aquamarine Birthstone',
      catid: 'cat210199',
    },
  },
  {
    'september-sapphire-birthstone': {
      name: 'September Sapphire Birthstone',
      catid: 'cat210205',
    },
  },
  {
    'red-funeral': {
      name: 'Red Funeral',
      catid: 'cat210218',
    },
  },
  {
    'green-plants': {
      name: 'Green Plants',
      catid: 'cat210184',
    },
  },
  {
    'bright-colored': {
      name: 'Bright-Colored',
      catid: 'cat210211',
    },
  },
  {
    'pink-for-mom': {
      name: 'Pink for Mom',
      catid: 'cat210196',
    },
  },
  {
    'plants-for-mom': {
      name: 'Plants for Mom',
      catid: 'cat490119',
    },
  },
  {
    'purple-for-mom': {
      name: 'Purple for Mom',
      catid: 'cat490120',
    },
  },
  {
    'yellow-for-mom': {
      name: 'Yellow for Mom',
      catid: 'cat490121',
    },
  },
  {
    'modern-mom-bouquets': {
      name: 'Modern Mom Bouquets',
      catid: 'cat490123',
    },
  },
  {
    'traditional-mom-bouquets': {
      name: 'Traditional Mom Bouquets',
      catid: 'cat490124',
    },
  },
  {
    lisianthus: {
      name: 'Lisianthus',
      catid: 'cat210154',
    },
  },
  {
    'french-country-ceramics': {
      name: 'French Country Ceramics',
      catid: 'cat480037',
    },
  },
  {
    stock: {
      name: 'Stock',
      catid: 'cat210165',
    },
  },
  {
    'labor-day': {
      name: 'Labor Day',
      catid: 'cat210086',
    },
  },
  {
    'cinco-de-mayo': {
      name: 'Cinco de Mayo',
      catid: 'cat210065',
    },
  },
  {
    'november-topaz-birthstone': {
      name: 'November Topaz Birthstone',
      catid: 'cat210207',
    },
  },
  {
    'independence-day': {
      name: 'Independence Day',
      catid: 'cat210083',
    },
  },
  {
    'keepsakes-(2-in-1-gifts)': {
      name: 'Keepsakes (2-in-1 gifts)',
      catid: 'cat480012',
    },
  },
  {
    'valentine-orchids': {
      name: 'Valentine Orchids',
      catid: 'cat210118',
    },
  },
  {
    'orchid-amp;-tropical-plants': {
      name: 'Orchid &amp; Tropical Plants',
      catid: 'cat210186',
    },
  },
  {
    'pink-funeral': {
      name: 'Pink Funeral',
      catid: 'cat210215',
    },
  },
  {
    'peach-sympathy': {
      name: 'Peach Sympathy',
      catid: 'cat640007',
    },
  },
  {
    'cream-funeral': {
      name: 'Cream Funeral',
      catid: 'cat210212',
    },
  },
  {
    'red-sympathy': {
      name: 'Red Sympathy',
      catid: 'cat640005',
    },
  },
  {
    'silver-reflections-vase': {
      name: 'Silver Reflections Vase',
      catid: 'cat480033',
    },
  },
  {
    'sunny-day-ceramic-pitcher': {
      name: 'Sunny Day Ceramic Pitcher',
      catid: 'cat480013',
    },
  },
  {
    tulips: {
      name: 'Tulips',
      catid: 'cat210168',
    },
  },
  {
    'valentine-tulips': {
      name: 'Valentine Tulips',
      catid: 'cat640012',
    },
  },
  {
    'religious-funeral-flowers': {
      name: 'Religious Funeral Flowers',
      catid: 'cat570002',
    },
  },
  {
    'blue-sympathy': {
      name: 'Blue Sympathy',
      catid: 'cat640004',
    },
  },
  {
    aster: {
      name: 'Aster',
      catid: 'cat210142',
    },
  },
  {
    dahlias: {
      name: 'Dahlias',
      catid: 'cat210147',
    },
  },
  {
    'votive-candle-holders': {
      name: 'Votive Candle Holders',
      catid: 'cat480039',
    },
  },
  {
    peony: {
      name: 'Peony',
      catid: 'cat210156',
    },
  },
  {
    'bunch-vase': {
      name: 'Bunch Vase',
      catid: 'cat480038',
    },
  },
  {
    'valentine-lilies': {
      name: 'Valentine Lilies',
      catid: 'cat210117',
    },
  },
  {
    'send-a-hug-day': {
      name: 'Send a Hug Day',
      catid: 'cat610015',
    },
  },
  {
    'red-amp;-white-funeral': {
      name: 'Red &amp; White Funeral',
      catid: 'cat210219',
    },
  },
  {
    passover: {
      name: 'Passover',
      catid: 'cat210103',
    },
  },
  {
    'best-sellers': {
      name: 'Best Sellers',
      catid: 'cat480011',
    },
  },
  {
    'mothers-day': {
      name: "Mother's Day",
      catid: 'cat210089',
    },
  },
  {
    delphinium: {
      name: 'Delphinium',
      catid: 'cat210148',
    },
  },
  {
    'december-turquoise-birthstone': {
      name: 'December Turquoise Birthstone',
      catid: 'cat210208',
    },
  },
  {
    'blush-colored-funeral': {
      name: 'Blush-Colored Funeral',
      catid: 'cat210210',
    },
  },
  {
    iris: {
      name: 'Iris',
      catid: 'cat210151',
    },
  },
  {
    'food-gift-baskets': {
      name: 'Food Gift Baskets',
      catid: 'cat210132',
    },
  },
  {
    'bestsellers-for-mom': {
      name: 'Bestsellers for Mom',
      catid: 'cat210090',
    },
  },
  {
    anniversary: {
      name: 'Anniversary',
      catid: 'cat440206',
    },
  },
  {
    'love-romance': {
      name: 'Love & Romance',
      catid: 'cat210087',
    },
  },
  {
    summer: {
      name: 'Summer',
      catid: 'cat210192',
    },
  },
  {
    'get-well': {
      name: 'Get Well',
      catid: 'cat210073',
    },
  },
  {
    'thank-you': {
      name: 'Thank You',
      catid: 'cat210110',
    },
  },
  {
    'thinking-of-you': {
      name: 'Thinking of You',
      catid: 'cat210115',
    },
  },
  {
    'business-gifts': {
      name: 'Business Gifts',
      catid: 'cat210263',
    },
  },
  {
    christmas: {
      name: 'Christmas',
      catid: 'cat210063',
    },
  },
  {
    birthday: {
      name: 'Birthday',
      catid: 'cat210012',
    },
  },
  {
    'new-baby': {
      name: 'New Baby',
      catid: 'cat210097',
    },
  },
  {
    'just-because': {
      name: 'Just Because',
      catid: 'cat210085',
    },
  },
  {
    congratulations: {
      name: 'Congratulations',
      catid: 'cat210066',
    },
  },
  {
    roses: {
      name: 'Roses',
      catid: 'cat210157',
    },
  },
  {
    'valentines-day': {
      name: "Valentine's Day",
      catid: 'cat210019',
    },
  },
  {
    spring: {
      name: 'Spring',
      catid: 'cat210195',
    },
  },
  {
    'new-years-eve': {
      name: "New Year's Eve",
      catid: 'cat210101',
    },
  },
];

export {
  Switch,
  FormGroup,
  CardHeader,
  Select,
  MenuItem,
  InputLabel,
  colors,
  stateClear,
  Stack,
  LinearProgress,
  Toolbar,
  RangeDateInput,
  TableCustomized,
  AlertMessage,
  TextField,
  CircularProgress,
  Autocomplete,
  Card,
  CardContent,
  CardActions,
  DialogAnimate,
  Modal,
  IconButton,
  Tooltip,
  format,
  startOfDay,
  endOfDay,
  useTheme,
  styled,
  Paper,
  Box,
  Grid,
  Divider,
  Typography,
  Iconify,
  Button,
  useState,
  useEffect,
  useContext,
  useRef,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  Table,
  TableCell,
  TableRow,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  red,
  blue,
  teal,
  green,
  purple,
  orange,
  Container,
  InputAdornment,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Checkbox,
  FormControl,
  ButtonBase,
  Alert,
  Snackbar,
  AlertTitle,
  categories,
};
