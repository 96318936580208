export function formatNoteText(note) {
    note = note.replace(/:x27;/g, "'");
    note = note.replace(/&#x27;/g, "'");
    note = note.replace(/#x27;/g, "'");
    note = note.replace(/x27;/g, "'");
    note = note.replace(/onand%/g, 'onand');
    note = note.replace(/&#x2F;/g, '/');
    note = note.replace(/#x2F;/g, '/');
    note = note.replace(/#/g, ':');
    note = note.replace(/&amp;/g, '&');
    note = note.replace(/ &amp; /g, '&');
    note = note.replace(/%20/g, ' ');
    note = note.replace(/%2C/g, ',');
    note = note.replace(/%25/g, ' percent');
    return note;
}
// &#x27;
