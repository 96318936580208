import { useEffect } from 'react';

import './app.css';
// routes
import Router from './routes';

// theme
import ThemeProvider from './theme';

// components
import { Alert, IconButton, Snackbar } from '@mui/material';
import Iconify from 'components/Iconify';
import NotistackProvider from './components/NotistackProvider';
import { ProgressBarStyle } from './components/ProgressBar';
import RtlLayout from './components/RtlLayout';
import ScrollToTop from './components/ScrollToTop';
import ThemeColorPresets from './components/ThemeColorPresets';
import ThemeLocalization from './components/ThemeLocalization';
import MotionLazyContainer from './components/animate/MotionLazyContainer';
import { ChartStyle } from './components/chart';
import Settings from './components/settings';

import { useDispatch, useSelector } from 'react-redux';
import { closeSnackbar } from 'redux/slices/generalSlice';

import 'bootstrap/dist/css/bootstrap.min.css';

import { SocketContext, socket } from 'contexts/SocketContext';

import { clearSocket, createSocket } from 'redux/slices/socketSlice';

import {
    addedNewOrder,
    deleteOnList,
    setBigOrderLoader,
    setOrderLoader,
    setOrders,
    updateOrderClaimed,
    updateOrderSocket,
    updateOrderTag,
} from 'redux/slices/ordersSlice';

import { updateUserPermissions, logout, setLoginActive } from 'redux/slices/authSlices';

import { useNavigate } from 'react-router-dom';

import axios from 'utils/axios';

import storage from 'utils/@storage';

// ----------------------------------------------------------------------

function App() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { snackbar, message, severity } = useSelector((state) => state.generals);
    const { isAuthenticated } = useSelector((state) => state.auth);
    const { socketId } = useSelector((state) => state.socket);

    const handleLogout = async () => {
        dispatch(setLoginActive(false));
        dispatch(logout());
    };
    useEffect(() => {
        if (!isAuthenticated) {
            dispatch(clearSocket());
            socket.removeAllListeners();
            socket.disconnect();
            navigate('/', { replace: true });
        }
        if (isAuthenticated) {
            socket.connect();
            socket.on('socketId', (e) => {
                storage.set('X-socket-id', e);
                axios.defaults.headers.common['X-socket-id'] = e;
                dispatch(createSocket({ socketId: e, socket }));
            });

            socket.on('orders', ({ orders, littleDataloading, bigDataloading }) => {
                dispatch(setOrders(orders));
                dispatch(setOrderLoader(littleDataloading));
                dispatch(setBigOrderLoader(bigDataloading));
            });

            socket.on('updateOrderTag', (val) => {
                dispatch(updateOrderTag(val));
            });

            socket.on('orderLocked', (val) => {
                dispatch(updateOrderSocket(val));
            });

            socket.on('deleteOnList', (val) => {
                dispatch(deleteOnList(val));
            });

            socket.on('updatePermissions', (val) => {
                if (Array.isArray(val)) {
                    dispatch(updateUserPermissions(val));
                }
            });
            socket.on('updateOrderClaimed', (val) => {
                if (val.id && val.claimedBy) {
                    dispatch(updateOrderClaimed(val));
                }
            });

            socket.on('listNewOrder', (val) => {
                dispatch(addedNewOrder(val));
            });

            socket.on('deletedThisSocketIdFromSystem', (val) => {
                handleLogout();
            });
        }
    }, [isAuthenticated, socketId]); // eslint-disable-line

    return (
        <ThemeProvider>
            <SocketContext.Provider
                value={{
                    socket,
                    socketId,
                }}
            >
                <ThemeColorPresets>
                    <ThemeLocalization>
                        <RtlLayout>
                            <NotistackProvider>
                                <MotionLazyContainer>
                                    <Snackbar
                                        open={snackbar}
                                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                        autoHideDuration={3000}
                                        message={message}
                                        action={
                                            <IconButton
                                                color="primary"
                                                onClick={() => {
                                                    dispatch(closeSnackbar());
                                                }}
                                            >
                                                <Iconify icon="eva:close-circle-outline" />
                                            </IconButton>
                                        }
                                        onClose={() => {
                                            dispatch(closeSnackbar());
                                        }}
                                    >
                                        <Alert
                                            onClose={() => {
                                                dispatch(closeSnackbar());
                                            }}
                                            severity={severity === '' ? 'success' : severity}
                                            sx={{ width: '100%' }}
                                        >
                                            {message}
                                        </Alert>
                                    </Snackbar>
                                    <ProgressBarStyle />
                                    <ChartStyle />
                                    <Settings />
                                    <ScrollToTop />
                                    <Router />
                                </MotionLazyContainer>
                            </NotistackProvider>
                        </RtlLayout>
                    </ThemeLocalization>
                </ThemeColorPresets>
            </SocketContext.Provider>
        </ThemeProvider>
    );
}

export default App;
