import { Box, Card, CardContent, Grid, Toolbar } from '@mui/material';
import { CustomButton } from 'components/CustomButtons';
import Page from 'components/Page';
import RangeDateInput from 'components/RangeDateInput';
import { TableCustomizedNew } from 'components/TableCustomized';
import { endOfDay, startOfDay } from 'date-fns';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import ReportsService from 'services/reportsService';
import CompletedBox from './components/CompletedBox';

const PhoneOrdersReports = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const startTimeParam = searchParams.get('starttime');
  const endTimeParam = searchParams.get('endtime');
  const initialStartTime = Number(startTimeParam) || startOfDay(new Date()).getTime();
  const initialEndTime = Number(endTimeParam) || endOfDay(new Date()).getTime();

  const [pageLoading, setIsPageLoading] = useState(true);
  const [starttime, setStartTime] = useState(initialStartTime);
  const [endtime, setEndTime] = useState(initialEndTime);
  const [orders, setOrders] = useState([]);
  const [selectedBox, setSelectedBox] = useState('Completed Phone Orders');
  const [cardData, setCardData] = useState();
  const [cardLoading, setCardLoading] = useState(true);
  const [filteredData, setFilteredData] = useState([]);

  // console.log(starttime); //1695070800000  1694984400000
  // console.log(endtime); //1695157199999  1695070799999

  const headCells = [
    {
      id: 'agent',
      align: 'left',
      disablePadding: false,
      label: t('headCells.agent'),
      short: 'agent',
      component: (row) => (
        <Box>
          <Grid container>
            <Grid item xs={12}>
              {row?.agent}
            </Grid>
          </Grid>
        </Box>
      ),
    },
    {
      id: 'number_of_orders_completed',
      align: 'center',
      disablePadding: false,
      label: t('headCells.number_of_orders_completed'),
      short: 'count',
      component: (row) => (
        <Box>
          <Grid container>
            <Grid item xs={12}>
              {row.count}
            </Grid>
          </Grid>
        </Box>
      ),
    },
    {
      id: 'sales',
      align: 'center',
      disablePadding: false,
      label: t('headCells.salesMargin'),
      short: 'totalMargin',
      component: (row) => (
        <Box>
          <Grid container>
            <Grid item xs={12}>
              <Box sx={{ padding: 0.1 }}>
                <Box>{row?.totalMargin.toFixed(2)} %</Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      ),
    },
    {
      id: 'total',
      align: 'center',
      disablePadding: false,
      label: t('headCells.totalPrice'),
      short: 'totalTotalPrice',
      component: (row) => (
        <Box>
          <Grid container>
            <Grid item xs={12}>
              <Box sx={{ padding: 0.1 }}>
                <Box>$ {row?.totalTotalPrice.toFixed(2)}</Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      ),
    },
    {
      id: 'detail',
      align: 'center',
      disablePadding: false,
      label: t('headCells.detail'),
      component: (row) => (
        <Box>
          <Link
            to={'/admin/reports/agent_detail_reports'}
            state={{
              stime: starttime,
              etime: endtime,
              agentName: row.agent,
              orderSource: selectedBox || '',
            }}
          >
            View Details
          </Link>
        </Box>
      ),
    },
  ];

  const getAllDailyinfo = async () => {
    setCardLoading(true);
    const data = await ReportsService.getDailyReports(starttime, endtime);
    setCardData(data);
    setCardLoading(false);
  };

  const getOrderValues = async (e) => {
    const data = await ReportsService.getOrderSourceTransactions(e, starttime, endtime);
    const filteredData = data.filter(
      (order) =>
        order.details.agent !== undefined && order.details.agent !== null && order.details.agent !== 'web order'
    );

    setOrders(filteredData);
  };

  const getCompletedPhoneOrdersList = async (e) => {
    const data = await ReportsService.getOrderSourceTransactions(e, starttime, endtime);
    const filteredData = data.filter(
      (order) =>
        order.order_tag === 'completed' &&
        order.details.agent !== undefined &&
        order.details.agent !== null &&
        order.details.agent !== 'web order'
    );
    setOrders(filteredData);
  };

  const completedObject = cardData && cardData.tagsTotal.find((item) => item.title === 'completed');
  const completedCount = cardData && cardData.tagsTotal.reduce((sum, obj) => sum + obj.number_of_phone_orders, 0);
  const phoneOrderCount = completedObject ? completedObject.number_of_phone_orders : 0;

  const averageMarginCompleted = (() => {
    if (completedObject && completedObject.average_margin) {
      return `${parseFloat(completedObject.average_margin).toFixed(2)}%`;
    }
    return '0%'; // Eğer completedObject veya average_margin yoksa 0% döndürülür.
  })();

  useEffect(() => {
    // eslint-disable-next-line no-unused-vars
    let unmounted = false;

    setSearchParams({
      starttime,
      endtime,
    });

    if (starttime != null && endtime != null) {
      getAllDailyinfo();
      if (selectedBox === 'Completed Phone Orders') {
        getCompletedPhoneOrdersList(selectedBox);
      } else {
        getOrderValues(selectedBox);
      }
      setIsPageLoading(false);
    } else {
      setIsPageLoading(true);
    }

    return () => {
      unmounted = true;
    };
  }, [starttime, endtime, selectedBox]);

  useEffect(() => {
    const newDataArray = [];

    orders.forEach((order) => {
      const { agent } = order.details;

      const margin = Number(order.details.margin);
      const totalPrice = Number(order.details.total_price);

      const agentIndex = newDataArray.findIndex((item) => item.agent === agent);

      if (agentIndex === -1) {
        // If the agent doesn't exist in the newDataArray, add a new entry
        newDataArray.push({
          agent,
          count: 1,
          totalMargin: margin,
          totalTotalPrice: totalPrice,
        });
      } else {
        // If the agent already exists in the newDataArray, update the existing entry
        newDataArray[agentIndex].count += 1;
        newDataArray[agentIndex].totalMargin += margin;
        newDataArray[agentIndex].totalTotalPrice += totalPrice;
      }
    });
    // Calculate total sales margin and update each agent's data
    newDataArray.forEach((item) => {
      item.totalMargin /= item.count; // Calculate total sales margin
    });

    setFilteredData(newDataArray);
  }, [orders]);

  return (
    <Page loading={pageLoading}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <CompletedBox
                  title="Total Phone Orders"
                  count={completedCount}
                  isLoading={cardLoading}
                  selectedBox={selectedBox}
                  setSelectedBox={setSelectedBox}
                />

                <CompletedBox
                  title="Completed Phone Orders"
                  count={phoneOrderCount}
                  isLoading={cardLoading}
                  selectedBox={selectedBox}
                  setSelectedBox={setSelectedBox}
                />

                <CompletedBox
                  title="Sales Margin"
                  count={averageMarginCompleted}
                  isLoading={cardLoading}
                  selectedBox={selectedBox}
                  setSelectedBox={setSelectedBox}
                  disabled
                />
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <TableCustomizedNew
            align="right"
            rows={filteredData}
            headCells={headCells}
            perpage={10}
            rowsperpage={[10, 25, 50]}
          >
            <Toolbar sx={{ display: 'flex', justifyContent: 'end' }}>
              <>
                <RangeDateInput
                  startdate={starttime}
                  enddate={endtime}
                  setStartDate={setStartTime}
                  setEndDate={setEndTime}
                  // onSubmit={() => {
                  //   getAllDailyinfo();
                  // }}
                ></RangeDateInput>
                <CustomButton
                  color="info"
                  variant={'contained'}
                  icon={'ooui:reload'}
                  onClick={() => {
                    setOrders([]);
                    setSelectedBox(null);
                  }}
                >
                  Clear Filter
                </CustomButton>
              </>
            </Toolbar>
          </TableCustomizedNew>
        </Grid>
      </Grid>
    </Page>
  );
};

export default PhoneOrdersReports;
