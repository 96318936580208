import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { styled, useTheme } from '@mui/material/styles';
import { visuallyHidden } from '@mui/utils';
import useSearchEngine from 'hooks/useSearchEngine';
import { useEffect, useMemo, useState } from 'react';

import CustomTollbar from 'components/Toolbar/CustomToolbar';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        borderRadius: 0,
        padding: 0,
        boxShadow: 'none',
    },
    '&:last-child td, &:last-child th': {
        border: 0,
        borderRadius: 0,
        margin: 0,
        boxShadow: 'none',
        backgroundColor: theme.palette.common.light,
    },
}));

function compareValues(a, b, isNumeric = false) {
    if (isNumeric) {
        a = Number(a);
        b = Number(b);
    }
    if (a < b) return -1;
    if (a > b) return 1;
    return 0;
}

const comparators = {
    price: (a, b) => compareValues(a.details.total_price, b.details.total_price, true),
    margin: (a, b) => compareValues(a.details.margin, b.details.margin, true),
    delivery: (a, b) => compareValues(a.order_details[0]?.delivery_date, b.order_details[0]?.delivery_date),
    order_date: (a, b) => compareValues(a.created_at, b.created_at),
    customer: (a, b) => compareValues(a.customer.firstname, b.customer.firstname),
    reason: (a, b) => compareValues(a.details?.reason, b.details?.reason),
    sub_order: (a, b) => compareValues(a.order_details.length, b.order_details.length, true),
    timezone: (a, b) => compareValues(a.details?.customerTimezone, b.details?.customerTimezone),
    city: (a, b) => compareValues(a.details?.stateshort, b.details?.stateshort),
    lastname: (a, b) => compareValues(a.customer.lastname, b.customer.lastname),
    firstname: (a, b) => compareValues(a.customer.firstname, b.customer.firstname),
    floristName: (a, b) =>
        compareValues(
            a.order_details[0]?.details?.fullfill?.floristName,
            b.order_details[0]?.details?.fullfill?.floristName
        ),
    rec_firstname: (a, b) =>
        compareValues(a.order_details[0]?.delivery?.firstname, b.order_details[0]?.delivery?.firstname),
    rec_lastname: (a, b) =>
        compareValues(a.order_details[0]?.delivery?.lastname, b.order_details[0]?.delivery?.lastname),
    state: (a, b) => compareValues(a.order_details[0].delivery.state, b.order_details[0].delivery.state),
    creditcardlast5digits: (a, b) =>
        compareValues(a.payment_logs[0].request.cardNumber, b.payment_logs[0].request.cardNumber),
    vendor: (a, b) =>
        compareValues(
            a.order_details[0]?.details?.fullfill?.vendorId,
            b.order_details[0]?.details?.fullfill?.vendorId,
            true
        ),
    vendorOrderID: (a, b) =>
        compareValues(
            a.order_details[0]?.details?.fullfill?.vendorOrderId,
            b.order_details[0]?.details?.fullfill?.vendorOrderId,
            true
        ),
    sales: (a, b) => compareValues(a.sales_margin, b.sales_margin, true),
    zipcode: (a, b) =>
        compareValues(a.order_details[0]?.delivery?.zipcode, b.order_details[0]?.delivery?.zipcode, true),

    agent_name: (a, b) => compareValues(a.details?.claimedBy?.firstname, b.details?.claimedBy?.firstname),
    refund_firstname: (a, b) => compareValues(a.details?.customer?.firstname, b.details?.customer?.firstname),
    refund_lastname: (a, b) => compareValues(a.details?.customer?.lastname, b.details?.customer?.lastname),
    refund_price: (a, b) => compareValues(a.details.total_price, b.details.total_price, true),
    debt_profit_total_price: (a, b) => compareValues(a.total_price, b.total_price, true),
    debt_profit_sending_price: (a, b) => compareValues(a.sending_price, b.sending_price, true),
    debt_profit_recipient_info: (a, b) =>
        compareValues(a.order_details?.delivery?.firstname, b.order_details?.delivery?.firstname),
};

function descendingComparator(a, b, orderBy) {
    const comparator = comparators[orderBy];
    if (comparator) {
        return comparator(a, b);
    }
    return compareValues(a[orderBy], b[orderBy]);
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5, fontFamily: 'arial', fontSize: '10px', alignItems: 'center' }}>
            <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
}

function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort, headCells } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <StyledTableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.align}
                        padding={'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            // eslint-disable-next-line no-nested-ternary
                            direction={orderBy === headCell.id ? (order === 'asc' ? 'desc' : 'asc') : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted ascending' : 'sorted descending'}
                                </Box>
                            ) : null}
                            {headCell.label}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </StyledTableRow>
        </TableHead>
    );
}

const TableCustomized = ({
    rows,
    headCells,
    date = '',
    clear = '',
    children,
    align,
    setSearchText = () => {},
    perpage = 25,
    rowsperpage = [25, 50, 100],
    header = '',
}) => {
    const searchEngine = useSearchEngine();
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('calories');
    const [selected, setSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(perpage);
    const [headList] = useState(headCells.map((item) => item.id));
    const [search, setSearch] = useState('');

    useEffect(() => {
        if (date !== '') {
            setSearch(date);
        }
    }, [date]);

    useEffect(() => {
        if (clear === '') {
            setSearch('');
        }
    }, [clear]);

    useEffect(() => {
        setSearchText(search);
    }, [search]);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = List.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const List = useMemo(() => searchEngine(rows, search), [rows, search]);

    const Row = ({ row, title }) => {
        const col = headCells.find((item) => item.id === title);

        if (col.component) {
            return col.component(row, title);
        }

        return <div>{row[title]}</div>;
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - List.length) : 0;

    return (
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2 }}>
                <CustomTollbar search={search} setSearch={setSearch} align={align} header={header}>
                    {children}
                </CustomTollbar>
                <TableContainer component={Paper}>
                    <Table aria-labelledby="tableTitle" size={'medium'}>
                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={List.length}
                            headCells={headCells}
                        />
                        <TableBody sx={{ minHeight: 250 }}>
                            {stableSort(List, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => (
                                    <TableRow
                                        hover
                                        onClick={(event) => handleClick(event, row.name)}
                                        role="checkbox"
                                        tabIndex={-1}
                                        key={index}
                                        sx={{ margin: '2px', borderBottom: '.1px solid #d5d5d5cf' }}
                                    >
                                        {headList.map((item, i) => {
                                            const { align } = headCells.find((x) => x.id === item);
                                            return (
                                                <TableCell key={i} align={align}>
                                                    <Row title={item} row={row}></Row>
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                ))}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: 40 * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={headCells.length} />
                                </TableRow>
                            )}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={rowsperpage}
                                    colSpan={headCells.length}
                                    count={List.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    SelectProps={{
                                        inputProps: {
                                            'aria-label': 'rows per page',
                                        },
                                        native: false,
                                    }}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    ActionsComponent={TablePaginationActions}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </Paper>
        </Box>
    );
};

export default TableCustomized;
